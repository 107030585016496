<template>
  <div class="pcr-page flex-col">
    <div class="pcr-wrap1 flex-col">
      <div class="pcr-section1 flex-col">
        <div class="pcr-wrap2 flex-col">
          <headInfo :type="type"></headInfo>
          <span class="pcr-txt4">CRM云服务</span>
          <span class="pcr-info1"
            >有效管理企业客户及销售过程，促进业务增长</span
          >
          <!-- <div class="pcr-main3 flex-col" @click="toNewPage(5)">
            <span class="pcr-txt5">立即试用</span>
          </div> -->
        </div>
        <div class="pcr-wrap3 flex-col">
          <div class="pcr-group1 flex-col"></div>
        </div>
      </div>
      <span class="pcr-word4">产品介绍</span>
      <span class="pcr-info2"
        >线索到合同签订全流程可视化透明跟进分析客户成交成功率大大增加合作概率</span
      >
      <div class="pcr-section2 flex-row justify-between">
        <div class="pcr-group4 flex-col"></div>
        <img
          class="pcr-img16"
          referrerpolicy="no-referrer"
          src="@/assets/images/procedure/crmtwo.png"
        />
        <div class="pcr-group5 flex-col"></div>
        <div class="pcr-group6 flex-col"></div>
      </div>
      <div class="pcr-section3 flex-row justify-between">
        <span class="pcr-word5">数据分析</span>
        <span class="pcr-info3">云端配置</span>
        <span class="pcr-word6">操作清晰</span>
        <span class="pcr-info4">精准定位</span>
      </div>
      <div class="pcr-section4 flex-row justify-between">
        <span class="pcr-word7"
          >专业数据分析，所有数据清晰明了，获取信息省时省力</span
        >
        <span class="pcr-txt6"
          >云端部署、无需搭建、省时省力、轻松高效，兼顾易用性和个性能力</span
        >
        <span class="pcr-word8"
          >界面简洁明了，交互流程清晰，上手快，操作简单</span
        >
        <span class="pcr-info5">客户管理、营销管理，各阶段服务自动化</span>
      </div>
      <div class="pcr-section5 flex-col">
        <div class="pcr-section6 flex-col">
          <span class="pcr-info6">产品亮点</span>
          <span class="pcr-txt7">运用AI推荐算法，精准获客效率提升几十倍</span>
          <div class="pcr-mod1 flex-col"></div>
          <div class="pcr-mod2 flex-row justify-between">
            <div class="pcr-main4 flex-col">
              <div class="pcr-main5 flex-col">
                <div class="pcr-layer1 flex-col">
                  <span class="pcr-info7">打造会员体系</span>
                  <span class="pcr-paragraph1"
                    >收集记录会员资料<br />将会员分层建设会员积分体系<br />差异化营销</span
                  >
                  <img
                    class="pcr-img3"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="pcr-pic1"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/crmhighone.png"
              />
            </div>
            <div class="pcr-main6 flex-col">
              <div class="pcr-section7 flex-col">
                <div class="pcr-main7 flex-col">
                  <span class="pcr-word9">强效客户管理</span>
                  <span class="pcr-paragraph2"
                    >客户标签自定义<br />互动追踪加强沟通<br />实时消息更新<br />发掘潜在客户群</span
                  >
                  <img
                    class="pcr-img4"
                    referrerpolicy="no-referrer"
                    src="@/assets/main/productright.png"
                  />
                </div>
              </div>
              <img
                class="pcr-pic2"
                referrerpolicy="no-referrer"
                src="@/assets/images/procedure/crmhightwo.png"
              />
            </div>
          </div>
        </div>
        <div class="pcr-section8 flex-col">
          <div class="pcr-block1 flex-col">
            <div class="pcr-section9 flex-col">
              <span class="pcr-info8">销售绩效排名</span>
              <span class="pcr-paragraph3"
                >销售人员工作汇总<br />智能排名便于管理</span
              >
              <img
                class="pcr-pic3"
                referrerpolicy="no-referrer"
                src="@/assets/main/productright.png"
              />
            </div>
          </div>
          <img
            class="pcr-img5"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/crmhighthird.png"
          />
        </div>
      </div>
      <span class="pcr-word10">轻松管理企业销售业务</span>
      <span class="pcr-word11"
        >让您的销售、营销和服务支持工作自动化，更好地服务更多的客户</span
      >
      <div class="pcr-section10 flex-row justify-between">
        <div class="pcr-outer1 flex-col">
          <div class="pcr-layer2 flex-row">
            <span class="pcr-word12">客户画像&nbsp;用户等级</span>
          </div>
          <div class="pcr-layer3 flex-row justify-between">
            <div class="pcr-wrap7 flex-col"></div>
            <span class="pcr-word13">多渠道整合线索资源，跟进客户的转化</span>
          </div>
          <div class="pcr-layer4 flex-row justify-between">
            <div class="pcr-mod34 flex-col"></div>
            <span class="pcr-info9">数据分析，可视化分析线索来源的转化率</span>
          </div>
          <div class="pcr-layer5 flex-row justify-between">
            <div class="pcr-box8 flex-col"></div>
            <span class="pcr-txt8">通过邮件功能，创建并开展有针对性的营销</span>
          </div>
          <div class="pcr-layer6 flex-row justify-between">
            <div class="pcr-layer15 flex-col"></div>
            <span class="pcr-info10">呼叫中心，记录客户每一次沟通记录</span>
          </div>
          <div class="pcr-outer2 flex-col"></div>
        </div>
        <!-- <div class="pcr-outer2 flex-col"></div> -->
        <div class="pcr-outer3 flex-col">
          <div class="pcr-mod4 flex-row">
            <span class="pcr-word14">合同回款&nbsp;全面把控</span>
          </div>
          <div class="pcr-mod5 flex-row justify-between">
            <div class="pcr-section32 flex-col"></div>
            <span class="pcr-info11"
              >合同关联发票回款，把控财务流程每个一环节</span
            >
          </div>
          <div class="pcr-mod6 flex-row justify-between">
            <div class="pcr-mod35 flex-col"></div>
            <span class="pcr-word15">设置回款计划，每一笔回款按时有序进行</span>
          </div>
          <div class="pcr-mod7 flex-row justify-between">
            <div class="pcr-bd8 flex-col"></div>
            <span class="pcr-word16"
              >API无缝对接财务系统，业务与财务流程全面打通</span
            >
          </div>
          <div class="pcr-mod8 flex-row justify-between">
            <div class="pcr-outer7 flex-col"></div>
            <span class="pcr-word17"
              >自定义合同/回款审批流，实时审核，业务高效流转</span
            >
          </div>
          <div class="pcr-outer2 flex-col"></div>
        </div>
      </div>
      <div class="pcr-section11 flex-row">
        <div class="pcr-main9 flex-col">
          <div class="pcr-section12 flex-row">
            <span class="pcr-txt9">核心功能</span>
          </div>
          <div class="pcr-section13 flex-row">
            <div class="pcr-mod9 flex-col"></div>
          </div>
          <div class="pcr-section14 flex-row justify-between">
            <img
              class="pcr-img17"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoreone.png"
            />
            <img
              class="pcr-img18"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoretwo.png"
            />
            <img
              class="pcr-img19"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcorethird.png"
            />
            <img
              class="pcr-img20"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcorefour.png"
            />
            <img
              class="pcr-img21"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcorefive.png"
            />
          </div>
          <div class="pcr-section15 flex-row">
            <span class="pcr-word18">客户管理</span>
            <span class="pcr-word19">完善进销存</span>
            <span class="pcr-info12">商业智能BI</span>
            <span class="pcr-word20">项目管理</span>
            <span class="pcr-word21">开放API</span>
          </div>
          <div class="pcr-section16 flex-row">
            <span class="pcr-word22"
              >从线索到回款的全方位管理，把控企业销售进度</span
            >
            <span class="pcr-info13"
              >从采购到入库，销售到出库整个流程高效把控</span
            >
            <span class="pcr-word23"
              >强大商业智能系统，实现数据全方位多纬度的展示分析</span
            >
            <span class="pcr-paragraph4"
              >任务分配更加高效<br />项目管理更加智能</span
            >
            <span class="pcr-word24"
              >支持钉钉、企业微信以及第三方ERP系统对接</span
            >
          </div>
          <div class="pcr-section17 flex-row justify-between">
            <img
              class="pcr-pic11"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoresix.png"
            />
            <img
              class="pcr-pic12"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoreseven.png"
            />
            <img
              class="pcr-img22"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoreeight.png"
            />
            <img
              class="pcr-pic13"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcorenine.png"
            />
            <img
              class="pcr-img23"
              referrerpolicy="no-referrer"
              src="@/assets/images/procedure/crmcoreten.png"
            />
          </div>
          <div class="pcr-section18 flex-row">
            <span class="pcr-word25">销售自动化</span>
            <span class="pcr-info14">营销自动化</span>
            <span class="pcr-info15">庞大数据库</span>
            <span class="pcr-word26">呼叫中心</span>
            <span class="pcr-txt10">社交获客</span>
          </div>
          <div class="pcr-section19 flex-row justify-between">
            <span class="pcr-info16"
              >洞察客户需求，优化流程，提高潜在客户转化效率</span
            >
            <span class="pcr-info17"
              >预测营销活动的效果，深入了解客户的喜好和购买习惯</span
            >
            <span class="pcr-txt11"
              >建立客户数据库，对销售、营销和客服部门进行共享</span
            >
            <span class="pcr-word27"
              >与CRM完全融合实现一键外呼，通话录音，来电弹屏</span
            >
            <span class="pcr-paragraph5">减少获客成本<br />便于精细化运营</span>
          </div>
        </div>
      </div>
      <span class="pcr-word33">产品架构</span>
      <span class="pcr-word34">线索管理、客户管理、智能分析的等全方位构建</span>
      <div class="pcr-section20 flex-col">
        <div class="pcr-section21 flex-row justify-between">
          <!-- <div class="pcr-layer7 flex-col justify-between">
            <div class="pcr-block2 flex-col">
              <div class="pcr-outer5 flex-col justify-between">
                <div class="pcr-main10 flex-row">
                  <span class="pcr-word35">线索管理</span>
                  <span class="pcr-info18">客户成功管理</span>
                  <span class="pcr-info19">办公管理</span>
                </div>
                <div class="pcr-main11 flex-row">
                  <div class="pcr-mod10 flex-col">
                    <span class="pcr-word36">租户管理</span>
                  </div>
                  <div class="pcr-mod11 flex-col">
                    <span class="pcr-info20">商机管理</span>
                  </div>
                  <div class="pcr-mod12 flex-col">
                    <span class="pcr-info21">会员管理</span>
                  </div>
                  <div class="pcr-mod13 flex-col">
                    <span class="pcr-word37">日志管理</span>
                  </div>
                  <div class="pcr-mod14 flex-col">
                    <span class="pcr-info22">联系人管理</span>
                  </div>
                  <div class="pcr-mod15 flex-col">
                    <span class="pcr-txt12">权益与应用管理</span>
                  </div>
                  <div class="pcr-mod16 flex-col">
                    <span class="pcr-info23">积分管理</span>
                  </div>
                  <div class="pcr-mod17 flex-col">
                    <span class="pcr-word38">日报管理</span>
                  </div>
                  <div class="pcr-mod18 flex-col">
                    <span class="pcr-txt13">潜在客户管理</span>
                  </div>
                  <div class="pcr-mod19 flex-col">
                    <span class="pcr-txt14">预警客户管理</span>
                  </div>
                  <div class="pcr-mod20 flex-col">
                    <span class="pcr-txt15">报价销货</span>
                  </div>
                  <div class="pcr-mod21 flex-col">
                    <span class="pcr-word39">任务管理</span>
                  </div>
                  <div class="pcr-mod22 flex-col">
                    <span class="pcr-txt16">公海管理</span>
                  </div>
                  <div class="pcr-mod23 flex-col">
                    <span class="pcr-info24">客户信息管理</span>
                  </div>
                  <div class="pcr-mod24 flex-col">
                    <span class="pcr-word40">批次需求计划</span>
                  </div>
                  <div class="pcr-mod25 flex-col">
                    <span class="pcr-txt17">系统公告</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pcr-block3 flex-col">
              <div class="pcr-group3 flex-row justify-between">
                <span class="pcr-info25">权限管理</span>
                <div class="pcr-section22 flex-row justify-between">
                  <div class="pcr-layer8 flex-col">
                    <span class="pcr-word41">组织人员管理</span>
                  </div>
                  <div class="pcr-layer9 flex-col">
                    <span class="pcr-word42">角色管理</span>
                  </div>
                  <div class="pcr-layer10 flex-col">
                    <span class="pcr-txt18">权限管理</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pcr-layer11 flex-col">
            <div class="pcr-box3 flex-col">
              <span class="pcr-info26">智能分析</span>
              <div class="pcr-main12 flex-col">
                <span class="pcr-word43">应用分析</span>
              </div>
              <div class="pcr-main13 flex-col">
                <span class="pcr-word44">销售漏斗分析</span>
              </div>
              <div class="pcr-main14 flex-col">
                <span class="pcr-txt19">排行榜</span>
              </div>
              <div class="pcr-main15 flex-col">
                <span class="pcr-word45">客户画像分析</span>
              </div>
              <div class="pcr-main16 flex-col">
                <span class="pcr-info27">员工业绩分析</span>
              </div>
              <div class="pcr-main17 flex-col">
                <span class="pcr-info28">员工办公分析</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <span class="pcr-txt20">典型案例</span>
      <div class="pcr-section23 flex-row justify-between">
        <div class="pcr-bd5 flex-col">
          <img
            class="pcr-pic8"
            referrerpolicy="no-referrer"
            src="@/assets/images/procedure/crminsone.png"
          />
        </div>
        <div class="pcr-bd6 flex-col">
          <span class="pcr-word46">中山定宇明</span>
          <span class="pcr-word47">传统销售行业公司，注重客户运营</span>
          <div class="pcr-block4 flex-col"></div>
          <span class="pcr-txt21">痛点：</span>
          <span class="pcr-infoBox1"
            >1.获客难度大<br />2.跟进进度无法清晰可见<br />3.打印物料较多，模板填写繁杂</span
          >
          <span class="pcr-word48">解决方案：</span>
          <span class="pcr-infoBox2"
            >1.提供精细化公司筛选，同步用户管理，保存跟进结果<br />2.全可视化操作，科学数据看板展现跟进记录，实时分析跟进数据<br />3.模板上云，多表同步，客户和合同信息自动化填入，提高工作效率</span
          >
          <div class="pcr-block5 flex-row">
            <span class="pcr-word49">提升跟进透明化</span>
            <span class="pcr-word50">50%</span>
            <div class="pcr-mod26 flex-col"></div>
            <span class="pcr-info29">提高获客效率</span>
            <span class="pcr-word51">40%</span>
            <div class="pcr-mod27 flex-col"></div>
          </div>
          <button class="pcr-block6 flex-col" @click="jumpPage('instance','header-ident')">
            <span class="pcr-info30">查看详情</span>
          </button>
        </div>
      </div>
      <secFoot />
      <footInfo />
    </div>
    <div class="pcr-wrap6 flex-col">
      <div class="pcr-mod28 flex-row">
        <span class="pcr-info41">分析商机&nbsp;驱动增长</span>
      </div>
      <div class="pcr-mod29 flex-row justify-between">
        <div class="pcr-mod36 flex-col"></div>
        <span class="pcr-word62">查看商机目前阶段及金额，预期销售额完成度</span>
      </div>
      <div class="pcr-mod30 flex-row justify-between">
        <div class="pcr-group7 flex-col"></div>
        <span class="pcr-word63">销售漏斗，直观销售瓶颈，找寻潜在增长机会</span>
      </div>
      <div class="pcr-mod31 flex-row justify-between">
        <div class="pcr-mod37 flex-col"></div>
        <span class="pcr-txt30">精细化管理每个商机阶段需要完成的任务</span>
      </div>
      <div class="pcr-mod33 flex-row justify-between">
        <div class="pcr-outer8 flex-col"></div>
        <span class="pcr-txt31">自定义商机组，配置不同产品的商机流程</span>
      </div>
      <div class="pcr-outer2 flex-col"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      constants: {},
    };
  },
  methods: {
    jumpPage(path,id) {
      var path=path
      var Id=id;
      localStorage.setItem('toId',Id);
      this.$router.push({
        name: path,
        params: {
          sel: 'four'
        }
      });
    },
    // 跳转页面
    toNewPage(index) {
      let url = "";
      // index的类型 0-主页 1-产品介绍 2-服务体系 3-典型案例 4-关于松云 5-预约注册
      switch (index) {
        case 0: //主页
          url = "/";
          break;
        case 1: // 产品介绍
          url = "/product/erp";
          break;
        case 2: // 服务体系
          url = "/system";
          break;
        case 3: //  典型案例
          url = "/instance/erp";
          break;
        case 4: //关于松云
          url = "/about";
          break;
        case 5: //预约注册
          url = "/register";
          break;
      }
      if (url == "") {
        return;
      }
      this.$router.push({ path: url });
    },
    // onClick_1() {
    //   alert(1);
    // },
    // onClick_2() {
    //   alert(1);
    // },
  },
};
</script>
<style  rel="stylesheet/scss" lang="scss">
.pcr-page {
  position: relative;
  width: 1920px;
  height: 7615px;
  margin: 0 auto;
  background-color: rgba(250, 250, 250, 1);
  overflow: hidden;
  .pcr-wrap1 {
    width: 1920px;
    height: 7615px;
    .pcr-section1 {
      // z-index: 245;
      height: 920px;
      overflow: hidden;
      background: url(../../assets/images/back/graythird.png)
        100% no-repeat;
      width: 1920px;
      position: relative;
      .pcr-wrap2 {
        z-index: 300;
        width: 1920px;
        height: 610px;
        .pcr-main1 {
          height: 100px;
          width: 1920px;
          .pcr-box1 {
            width: 1775px;
            height: 42px;
            margin: 29px 0 0 65px;
            .pcr-img1 {
              width: 180px;
              height: 38px;
              margin-top: 2px;
            }
            .pcr-word1 {
              width: 43px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 301px;
            }
            .pcr-txt1 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiB;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pcr-word2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pcr-txt2 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pcr-word3 {
              width: 85px;
              height: 29px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 0.88);
              font-size: 21px;
              letter-spacing: 0.41999998688697815px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 29px;
              text-align: left;
              margin: 7px 0 0 104px;
            }
            .pcr-main2 {
              height: 42px;
              border-radius: 21px;
              border: 2px solid rgba(37, 184, 47, 1);
              margin-left: 346px;
              width: 149px;
              .pcr-txt3 {
                width: 85px;
                height: 29px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(255, 255, 255, 0.94);
                font-size: 21px;
                letter-spacing: 0.41999998688697815px;
                font-family: AlibabaPuHuiTiR;
                white-space: nowrap;
                line-height: 29px;
                text-align: left;
                margin: 7px 0 0 32px;
              }
            }
          }
        }
        .pcr-txt4 {
          width: 249px;
          height: 65px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 48px;
          letter-spacing: 0.9599999785423279px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 65px;
          text-align: right;
          margin: 221px 0 0 1471px;
        }
        .pcr-info1 {
          width: 842px;
          height: 52px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 28px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 52px;
          text-align: right;
          margin: 36px 0 0 878px;
        }
        .pcr-main3 {
          height: 68px;
          border-radius: 34px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.32);
          width: 220px;
          margin: 68px 0 0 1500px;
          .pcr-txt5 {
            width: 113px;
            height: 38px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 28px;
            letter-spacing: 0.5600000023841858px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 38px;
            text-align: left;
            margin: 15px 0 0 54px;
          }
        }
      }
      .pcr-wrap3 {
        z-index: 246;
        height: 920px;
        background: url(../../assets/main/crm.png)
          100% no-repeat;
        width: 1920px;
        position: absolute;
        left: 0;
        top: 0;
        .pcr-group1 {
          width: 1920px;
          height: 920px;
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .pcr-word4 {
      width: 129px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(39, 39, 39, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 896px;
    }
    .pcr-info2 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pcr-section2 {
      width: 1204px;
      height: 134px;
      margin: 80px 0 0 361px;
      .pcr-group4 {
        width: 116px;
        height: 116px;
        background: url(../../assets/images/procedure/crmone.png) -1px -1px
          no-repeat;
        margin-top: 10px;
      }
      .pcr-img16 {
        width: 132px;
        height: 133px;
      }
      .pcr-group5 {
        width: 109px;
        height: 131px;
        background: url(../../assets/images/procedure/crmthird.png) -1px -2px
          no-repeat;
        margin-top: 3px;
      }
      .pcr-group6 {
        width: 131px;
        height: 131px;
        background: url(../../assets/images/procedure/crmfour.png) -1px -1px
          no-repeat;
        margin-top: 3px;
      }
    }
    .pcr-section3 {
      width: 1165px;
      height: 29px;
      margin: 46px 0 0 376px;
      .pcr-word5 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pcr-info3 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
      .pcr-word6 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: center;
      }
      .pcr-info4 {
        width: 85px;
        height: 29px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 21px;
        letter-spacing: 0.41999998688697815px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 29px;
        text-align: left;
      }
    }
    .pcr-section4 {
      width: 1325px;
      height: 56px;
      margin: 16px 0 0 296px;
      .pcr-word7 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-txt6 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-word8 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-info5 {
        width: 245px;
        height: 56px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 28px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pcr-section5 {
      z-index: 230;
      height: 946px;
      background-color: rgba(37, 38, 37, 1);
      margin-top: 120px;
      width: 1920px;
      position: relative;
      .pcr-section6 {
        width: 1404px;
        height: 810px;
        margin-top: 68px;
        .pcr-info6 {
          width: 129px;
          height: 44px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 32px;
          letter-spacing: 0.6399999856948853px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 44px;
          text-align: left;
          margin-left: 897px;
        }
        .pcr-txt7 {
          width: 366px;
          height: 32px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          letter-spacing: 1.5px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 32px;
          text-align: center;
          margin: 24px 0 0 779px;
        }
        .pcr-mod1 {
          width: 84px;
          height: 6px;
          background-color: rgba(37, 184, 47, 1);
          margin: 12px 0 0 920px;
        }
        .pcr-mod2 {
          width: 1404px;
          height: 644px;
          margin-top: 48px;
          .pcr-main4 {
            z-index: 234;
            height: 644px;
            overflow: hidden;
            background: url(../../assets/images/back/graycrmone.png)
              100% no-repeat;
            width: 884px;
            position: relative;
            .pcr-main5 {
              z-index: 236;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/greyone.png)
                100% no-repeat;
              width: 884px;
              position: relative;
              .pcr-layer1 {
                width: 245px;
                height: 257px;
                margin: 248px 0 0 200px;
                .pcr-info7 {
                  width: 217px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                }
                .pcr-paragraph1 {
                  width: 245px;
                  height: 84px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  margin-top: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .pcr-img3 {
                  width: 55px;
                  height: 55px;
                  margin-top: 52px;
                }
              }
            }
            .pcr-pic1 {
              z-index: 235;
              position: absolute;
              left: 0;
              top: 0;
              width: 884px;
              height: 644px;
            }
          }
          .pcr-main6 {
            z-index: 429;
            height: 644px;
            overflow: hidden;
            background: url(../../assets/images/back/greytwo.png)
              100% no-repeat;
            width: 520px;
            position: relative;
            .pcr-section7 {
              z-index: 431;
              height: 644px;
              overflow: hidden;
              background: url(../../assets/images/back/greythird.png)
                100% no-repeat;
              width: 520px;
              position: relative;
              .pcr-main7 {
                width: 247px;
                height: 257px;
                margin: 248px 0 0 120px;
                .pcr-word9 {
                  width: 217px;
                  height: 50px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 36px;
                  letter-spacing: 0.7200000286102295px;
                  font-family: AlibabaPuHuiTiM;
                  white-space: nowrap;
                  line-height: 50px;
                  text-align: left;
                  margin-left: 2px;
                }
                .pcr-paragraph2 {
                  width: 245px;
                  height: 112px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(255, 255, 255, 1);
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  font-family: AlibabaPuHuiTiM;
                  line-height: 28px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 16px 0 0 2px;
                }
                .pcr-img4 {
                  width: 55px;
                  height: 55px;
                  margin-top: 24px;
                }
              }
            }
            .pcr-pic2 {
              z-index: 430;
              position: absolute;
              left: 0;
              top: 0;
              width: 520px;
              height: 644px;
            }
          }
        }
      }
      .pcr-section8 {
        z-index: 433;
        height: 644px;
        overflow: hidden;
        background: url(../../assets/images/back/greyfour.png)
          100% no-repeat;
        width: 517px;
        position: absolute;
        left: 1403px;
        top: 234px;
        .pcr-block1 {
          z-index: 435;
          height: 644px;
          overflow: hidden;
          background: url(../../assets/images/back/greyfive.png)
            100% no-repeat;
          width: 517px;
          position: relative;
          .pcr-section9 {
            width: 245px;
            height: 257px;
            margin: 248px 0 0 120px;
            .pcr-info8 {
              width: 217px;
              height: 50px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 36px;
              letter-spacing: 0.7200000286102295px;
              font-family: AlibabaPuHuiTiM;
              white-space: nowrap;
              line-height: 50px;
              text-align: left;
            }
            .pcr-paragraph3 {
              width: 245px;
              height: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 1.5px;
              font-family: AlibabaPuHuiTiM;
              line-height: 28px;
              text-align: left;
              margin-top: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .pcr-pic3 {
              width: 55px;
              height: 55px;
              margin-top: 80px;
            }
          }
        }
        .pcr-img5 {
          z-index: 434;
          position: absolute;
          left: 0;
          top: 0;
          width: 517px;
          height: 644px;
        }
      }
    }
    .pcr-word10 {
      width: 322px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 0.6399999856948853px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 799px;
    }
    .pcr-word11 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pcr-section10 {
      width: 1520px;
      height: 409px;
      margin: 52px 0 0 200px;
      .pcr-outer1 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 488px;
        .pcr-layer2 {
          width: 200px;
          height: 33px;
          margin: 76px 0 0 144px;
          .pcr-word12 {
            width: 200px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .pcr-layer3 {
          width: 321px;
          height: 28px;
          margin: 28px 0 0 76px;
          .pcr-wrap7 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-word13 {
            width: 291px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-layer4 {
          width: 338px;
          height: 28px;
          margin: 20px 0 0 76px;
          .pcr-mod34 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-info9 {
            width: 308px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-layer5 {
          width: 355px;
          height: 28px;
          margin: 20px 0 0 76px;
          .pcr-box8 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-txt8 {
            width: 325px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-layer6 {
          width: 304px;
          height: 28px;
          margin: 20px 0 87px 76px;
          .pcr-layer15 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-info10 {
            width: 274px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .pcr-outer1:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pcr-word12,
        .pcr-word13,
        .pcr-info9,
        .pcr-txt8,
        .pcr-info10 {
          color: rgba(255, 255, 255, 1);
        }
        .pcr-wrap7,
        .pcr-mod34,
        .pcr-box8,
        .pcr-layer15 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pcr-outer2 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
        }
      }
      .pcr-outer3 {
        height: 396px;
        border: 1px solid rgba(245, 245, 245, 1);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
        width: 487px;
        .pcr-mod4 {
          width: 200px;
          height: 33px;
          margin: 76px 0 0 144px;
          .pcr-word14 {
            width: 200px;
            height: 33px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(40, 40, 40, 1);
            font-size: 24px;
            letter-spacing: 0.47999998927116394px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 33px;
            text-align: left;
          }
        }
        .pcr-mod5 {
          width: 372px;
          height: 28px;
          margin: 28px 0 0 44px;
          .pcr-section32 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-info11 {
            width: 342px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-mod6 {
          width: 338px;
          height: 28px;
          margin: 20px 0 0 44px;
          .pcr-mod35 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-word15 {
            width: 308px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-mod7 {
          width: 401px;
          height: 28px;
          margin: 20px 0 0 44px;
          .pcr-bd8 {
            width: 21px;
            height: 22px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-word16 {
            width: 371px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
        .pcr-mod8 {
          width: 416px;
          height: 28px;
          margin: 20px 0 87px 44px;
          .pcr-outer7 {
            width: 21px;
            height: 21px;
            background: url(../../assets/images/procedure/tick.png)
              0px 0px no-repeat;
            margin-top: 4px;
          }
          .pcr-word17 {
            width: 386px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 28px;
            text-align: left;
          }
        }
      }
      .pcr-outer3:hover {
        background-color: rgba(37, 184, 47, 1);
        box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
        .pcr-word14,
        .pcr-info11,
        .pcr-word15,
        .pcr-word16,
        .pcr-word17 {
          color: rgba(255, 255, 255, 1);
        }
        .pcr-section32,
        .pcr-mod35,
        .pcr-bd8,
        .pcr-outer7 {
          background: url(../../assets/images/procedure/whitetick.png)
            0px 0px no-repeat;
        }
        .pcr-outer2 {
          width: 259px;
          height: 165px;
          background: url(../../assets/images/back/greencloth.png) 100%
            no-repeat;
          margin: -83px 0 0 114px;
          // margin-top: -12px;
          // margin-left: 172px;
        }
      }
    }
    .pcr-section11 {
      width: 1920px;
      height: 838px;
      margin-top: 107px;
      .pcr-main9 {
        height: 838px;
        background-color: rgba(37, 184, 47, 1);
        width: 1920px;
        .pcr-section12 {
          width: 129px;
          height: 44px;
          margin: 80px 0 0 896px;
          .pcr-txt9 {
            width: 129px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 32px;
            letter-spacing: 0.6399999856948853px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 44px;
            text-align: left;
          }
        }
        .pcr-section13 {
          width: 84px;
          height: 6px;
          margin: 24px 0 0 918px;
          .pcr-mod9 {
            width: 84px;
            height: 6px;
            background-color: rgba(255, 255, 255, 1);
          }
        }
        .pcr-section14 {
          width: 1111px;
          height: 120px;
          margin: 76px 0 0 405px;
          .pcr-img17 {
            width: 120px;
            height: 120px;
          }
          .pcr-img18 {
            width: 120px;
            height: 120px;
          }
          .pcr-img19 {
            width: 120px;
            height: 120px;
          }
          .pcr-img20 {
            width: 120px;
            height: 120px;
          }
          .pcr-img21 {
            width: 120px;
            height: 120px;
          }
        }
        .pcr-section15 {
          width: 1058px;
          height: 28px;
          margin: 20px 0 0 431px;
          .pcr-word18 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pcr-word19 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 171px;
          }
          .pcr-info12 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 161px;
          }
          .pcr-word20 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 170px;
          }
          .pcr-word21 {
            width: 65px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 181px;
          }
        }
        .pcr-section16 {
          width: 1179px;
          height: 44px;
          margin: 3px 0 0 371px;
          .pcr-word22 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-info13 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            margin-left: 60px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-word23 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            margin-left: 59px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-paragraph4 {
            width: 120px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            margin-left: 94px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-word24 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            margin-left: 94px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .pcr-section17 {
          width: 1111px;
          height: 120px;
          margin: 56px 0 0 405px;
          .pcr-pic11 {
            width: 120px;
            height: 120px;
          }
          .pcr-pic12 {
            width: 120px;
            height: 120px;
          }
          .pcr-img22 {
            width: 120px;
            height: 120px;
          }
          .pcr-pic13 {
            width: 120px;
            height: 120px;
          }
          .pcr-img23 {
            width: 120px;
            height: 120px;
          }
        }
        .pcr-section18 {
          width: 1068px;
          height: 28px;
          margin: 20px 0 0 423px;
          .pcr-word25 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
          }
          .pcr-info14 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 162px;
          }
          .pcr-info15 {
            width: 86px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 161px;
          }
          .pcr-word26 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 170px;
          }
          .pcr-txt10 {
            width: 69px;
            height: 28px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 1.3333333730697632px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 28px;
            text-align: center;
            margin-left: 179px;
          }
        }
        .pcr-section19 {
          width: 1179px;
          height: 44px;
          margin: 3px 0 122px 371px;
          .pcr-info16 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-info17 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-txt11 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-word27 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-paragraph5 {
            width: 188px;
            height: 44px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            letter-spacing: 1.1666666269302368px;
            font-family: AlibabaPuHuiTiR;
            line-height: 22px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .pcr-word28 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 -1486px;
      }
      .pcr-word29 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 188px;
      }
      .pcr-word30 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 187px;
      }
      .pcr-word31 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 0 0 188px;
      }
      .pcr-word32 {
        width: 60px;
        height: 24px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 24px;
        text-align: center;
        margin: 662px 435px 0 188px;
      }
    }
    .pcr-word33 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 890px;
    }
    .pcr-word34 {
      width: 898px;
      height: 32px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 18px;
      letter-spacing: 1.5px;
      font-family: AlibabaPuHuiTiR;
      white-space: nowrap;
      line-height: 32px;
      text-align: center;
      margin: 24px 0 0 511px;
    }
    .pcr-section20 {
      height: 822px;
      background-color: rgba(255, 255, 255, 1);
      margin-top: 36px;
      width: 1920px;
      .pcr-section21 {
        width: 1041px;
        height: 583px;
        margin: 108px 0 0 440px;
        background: url(../../assets/images/procedure/crmflow.png) 
          100% no-repeat;
        .pcr-layer7 {
          width: 832px;
          height: 583px;
          .pcr-block2 {
            height: 453px;
            background-color: rgba(0, 255, 17, 0.05);
            width: 832px;
            .pcr-outer5 {
              width: 760px;
              height: 374px;
              margin: 28px 0 0 37px;
              .pcr-main10 {
                width: 672px;
                height: 32px;
                .pcr-word35 {
                  width: 69px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 32px;
                  text-align: left;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .pcr-info18 {
                  width: 103px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 32px;
                  text-align: left;
                  margin-left: 132px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .pcr-info19 {
                  width: 69px;
                  height: 32px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 40, 40, 1);
                  font-size: 16px;
                  letter-spacing: 1.3333333730697632px;
                  font-family: AlibabaPuHuiTiR;
                  line-height: 32px;
                  text-align: left;
                  margin-left: 299px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .pcr-main11 {
                width: 760px;
                height: 318px;
                margin-top: 24px;
                flex-wrap: wrap;
                .pcr-mod10 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-word36 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod11 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-info20 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod12 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-info21 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod13 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  margin-bottom: 26px;
                  width: 157px;
                  .pcr-word37 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod14 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-info22 {
                    width: 86px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 36px;
                  }
                }
                .pcr-mod15 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-txt12 {
                    width: 120px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 19px;
                  }
                }
                .pcr-mod16 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-info23 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod17 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  margin-bottom: 26px;
                  width: 157px;
                  .pcr-word38 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod18 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-txt13 {
                    width: 103px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 27px;
                  }
                }
                .pcr-mod19 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-txt14 {
                    width: 103px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 27px;
                  }
                }
                .pcr-mod20 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-txt15 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod21 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  margin-bottom: 26px;
                  width: 157px;
                  .pcr-word39 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod22 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-txt16 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-mod23 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-info24 {
                    width: 103px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 27px;
                  }
                }
                .pcr-mod24 {
                  height: 60px;
                  background: url(../../assets/images/back/greenblock.png)
                    100% no-repeat;
                  width: 157px;
                  margin: 0 44px 26px 0;
                  .pcr-word40 {
                    width: 103px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 27px;
                  }
                }
                .pcr-mod25 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  margin-bottom: 26px;
                  width: 157px;
                  .pcr-txt17 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
              }
            }
          }
          .pcr-block3 {
            height: 114px;
            background-color: rgba(0, 255, 17, 0.05);
            margin-top: 16px;
            width: 832px;
            .pcr-group3 {
              width: 716px;
              height: 60px;
              margin: 27px 0 0 81px;
              .pcr-info25 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(40, 40, 40, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: left;
                margin-top: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .pcr-section22 {
                width: 559px;
                height: 60px;
                .pcr-layer8 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  .pcr-word41 {
                    width: 103px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 27px;
                  }
                }
                .pcr-layer9 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  .pcr-word42 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
                .pcr-layer10 {
                  height: 60px;
                  border: 1px solid rgba(37, 184, 47, 1);
                  background-color: rgba(225, 255, 227, 1);
                  width: 157px;
                  .pcr-txt18 {
                    width: 69px;
                    height: 32px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(18, 112, 24, 1);
                    font-size: 16px;
                    letter-spacing: 1.3333333730697632px;
                    font-family: AlibabaPuHuiTiM;
                    line-height: 32px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 14px 0 0 44px;
                  }
                }
              }
            }
          }
        }
        .pcr-layer11 {
          height: 583px;
          background-color: rgba(255, 194, 0, 0.06);
          width: 193px;
          .pcr-box3 {
            width: 157px;
            height: 546px;
            margin: 28px 0 0 17px;
            .pcr-info26 {
              width: 69px;
              height: 32px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(40, 40, 40, 1);
              font-size: 16px;
              letter-spacing: 1.3333333730697632px;
              font-family: AlibabaPuHuiTiR;
              line-height: 32px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .pcr-main12 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 24px;
              width: 157px;
              .pcr-word43 {
                width: 69px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 44px;
              }
            }
            .pcr-main13 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 26px;
              width: 157px;
              .pcr-word44 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
            .pcr-main14 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 26px;
              width: 157px;
              .pcr-txt19 {
                width: 52px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 53px;
              }
            }
            .pcr-main15 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 26px;
              width: 157px;
              .pcr-word45 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
            .pcr-main16 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 26px;
              width: 157px;
              .pcr-info27 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
            .pcr-main17 {
              height: 60px;
              border: 1px dashed rgba(37, 184, 47, 1);
              margin-top: 26px;
              width: 157px;
              .pcr-info28 {
                width: 103px;
                height: 32px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(18, 112, 24, 1);
                font-size: 16px;
                letter-spacing: 1.3333333730697632px;
                font-family: AlibabaPuHuiTiR;
                line-height: 32px;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 14px 0 0 27px;
              }
            }
          }
        }
      }
    }
    .pcr-txt20 {
      width: 134px;
      height: 44px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 32px;
      letter-spacing: 2px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 44px;
      text-align: left;
      margin: 120px 0 0 890px;
    }
    .pcr-section23 {
      width: 1262px;
      height: 545px;
      margin: 60px 0 0 329px;
      .pcr-bd5 {
        position: relative;
        width: 516px;
        height: 545px;
        overflow: hidden;
        background: url(../../assets/images/back/greysix.png)
          100% no-repeat;
        .pcr-pic8 {
          position: absolute;
          left: 0;
          top: 0;
          width: 516px;
          height: 545px;
        }
      }
      .pcr-bd6 {
        width: 645px;
        height: 545px;
        .pcr-word46 {
          width: 114px;
          height: 29px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 21px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 29px;
          text-align: left;
        }
        .pcr-word47 {
          width: 233px;
          height: 20px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
        .pcr-block4 {
          width: 84px;
          height: 4px;
          background-color: rgba(37, 184, 47, 1);
          margin-top: 10px;
        }
        .pcr-txt21 {
          width: 54px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 20px;
        }
        .pcr-infoBox1 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pcr-word48 {
          width: 89px;
          height: 30px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(40, 40, 40, 1);
          font-size: 16px;
          letter-spacing: 2px;
          font-family: AlibabaPuHuiTiM;
          white-space: nowrap;
          line-height: 30px;
          text-align: left;
          margin-top: 8px;
        }
        .pcr-infoBox2 {
          width: 645px;
          height: 90px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          line-height: 30px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .pcr-block5 {
          width: 312px;
          height: 77px;
          margin: 32px 0 0 64px;
          .pcr-word49 {
            width: 94px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 12px;
            letter-spacing: 1.5px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin-top: 55px;
          }
          .pcr-word50 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: -94px;
          }
          .pcr-mod26 {
            width: 18px;
            height: 32px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 -2px;
          }
          .pcr-info29 {
            width: 80px;
            height: 22px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(102, 102, 102, 1);
            font-size: 12px;
            letter-spacing: 1.5px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
            margin: 55px 0 0 108px;
          }
          .pcr-word51 {
            width: 86px;
            height: 55px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(37, 184, 47, 1);
            font-size: 40px;
            letter-spacing: 2px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 55px;
            text-align: left;
            margin-left: -80px;
          }
          .pcr-mod27 {
            width: 18px;
            height: 32px;
            background: url(../../assets/images/procedure/hook.png) -1px -2px
              no-repeat;
            margin: 27px 0 0 -2px;
          }
        }
        .pcr-block6 {
          height: 60px;
          background-color: rgba(37, 184, 47, 1);
          box-shadow: 0px 12px 24px 0px rgba(37, 184, 47, 0.2);
          margin-top: 39px;
          width: 180px;
          cursor: pointer;
          .pcr-info30 {
            width: 85px;
            height: 29px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 0.41999998688697815px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 29px;
            text-align: left;
            margin: 15px 0 0 47px;
          }
        }
      }
    }
    .pcr-word52 {
      width: 181px;
      height: 50px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(40, 40, 40, 1);
      font-size: 36px;
      letter-spacing: 0.7200000286102295px;
      font-family: AlibabaPuHuiTiM;
      white-space: nowrap;
      line-height: 50px;
      text-align: left;
      margin: 120px 0 0 869px;
    }
    .pcr-section24 {
      width: 1236px;
      height: 264px;
      margin: 32px 0 0 342px;
      .pcr-img12 {
        width: 340px;
        height: 264px;
      }
      .pcr-label1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .pcr-img13 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .pcr-icon1 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .pcr-pic9 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .pcr-section25 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pcr-info31 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pcr-txt22 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pcr-info32 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pcr-section26 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pcr-infoBox3 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-paragraph6 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-infoBox4 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pcr-section27 {
      width: 1236px;
      height: 264px;
      margin: 40px 0 0 342px;
      .pcr-img14 {
        width: 340px;
        height: 264px;
      }
      .pcr-label2 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 28px;
      }
      .pcr-img15 {
        width: 340px;
        height: 264px;
        margin-left: 34px;
      }
      .pcr-icon2 {
        width: 46px;
        height: 25px;
        margin: 120px 0 0 34px;
      }
      .pcr-pic10 {
        width: 340px;
        height: 264px;
        margin-left: 28px;
      }
    }
    .pcr-section28 {
      width: 969px;
      height: 25px;
      margin-left: 476px;
      .pcr-txt23 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pcr-info33 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
      .pcr-word53 {
        width: 73px;
        height: 25px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(39, 39, 39, 1);
        font-size: 18px;
        letter-spacing: 0.36000001430511475px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }
    .pcr-section29 {
      width: 1141px;
      height: 48px;
      margin: 6px 0 0 390px;
      .pcr-paragraph7 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-paragraph8 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pcr-paragraph9 {
        width: 245px;
        height: 48px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
        letter-spacing: 1.1666666269302368px;
        font-family: AlibabaPuHuiTiR;
        line-height: 24px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pcr-section30 {
      height: 527px;
      background-color: rgba(38, 38, 38, 1);
      margin-top: 120px;
      width: 1920px;
      .pcr-layer12 {
        width: 1520px;
        height: 362px;
        margin: 81px 0 0 201px;
        .pcr-box4 {
          width: 1015px;
          height: 36px;
          margin-left: 128px;
          .pcr-word54 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-info34 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 156px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-info35 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-txt24 {
            width: 38px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            letter-spacing: 0.8571428656578064px;
            font-family: AlibabaPuHuiTiM;
            line-height: 36px;
            text-align: left;
            margin-left: 116px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .pcr-info36 {
            width: 87px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 21px;
            letter-spacing: 1px;
            font-family: AlibabaPuHuiTiM;
            white-space: nowrap;
            line-height: 36px;
            text-align: left;
            margin-left: 388px;
          }
        }
        .pcr-box5 {
          width: 1138px;
          height: 56px;
          margin: 20px 0 0 128px;
          .pcr-layer13 {
            width: 73px;
            height: 56px;
            .pcr-info37 {
              width: 73px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pcr-txt25 {
              width: 64px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pcr-layer14 {
            width: 67px;
            height: 56px;
            margin-left: 121px;
            .pcr-txt26 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pcr-word55 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pcr-info38 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pcr-word56 {
            width: 67px;
            height: 24px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            white-space: nowrap;
            line-height: 24px;
            text-align: left;
            margin-left: 87px;
          }
          .pcr-word57 {
            width: 210px;
            height: 36px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            letter-spacing: 0.761904776096344px;
            font-family: AlibabaPuHuiTiR;
            line-height: 36px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 16px 0 0 359px;
          }
        }
        .pcr-box6 {
          width: 1262px;
          height: 88px;
          margin: 8px 0 0 128px;
          .pcr-main18 {
            width: 77px;
            height: 88px;
            .pcr-word58 {
              width: 68px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pcr-word59 {
              width: 77px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .pcr-txt27 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pcr-main19 {
            width: 67px;
            height: 56px;
            margin-left: 117px;
            .pcr-word60 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
            }
            .pcr-info39 {
              width: 67px;
              height: 24px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              white-space: nowrap;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
          }
          .pcr-main20 {
            width: 334px;
            height: 84px;
            margin-left: 667px;
            .pcr-txt28 {
              width: 334px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .pcr-info40 {
              width: 220px;
              height: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 16px;
              letter-spacing: 0.761904776096344px;
              font-family: AlibabaPuHuiTiR;
              line-height: 36px;
              text-align: left;
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .pcr-word61 {
          width: 68px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 16px;
          letter-spacing: 0.761904776096344px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 8px 0 0 128px;
        }
        .pcr-txt29 {
          width: 632px;
          height: 24px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.8);
          font-size: 14px;
          letter-spacing: 1.75px;
          font-family: AlibabaPuHuiTiR;
          white-space: nowrap;
          line-height: 24px;
          text-align: left;
          margin: 81px 0 0 443px;
        }
        .pcr-box7 {
          width: 1520px;
          height: 1px;
          margin-top: 16px;
        }
      }
    }
  }
  .pcr-wrap6 {
    z-index: 202;
    height: 396px;
    border: 1px solid rgba(245, 245, 245, 1);
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.06);
    width: 488px;
    position: absolute;
    left: 716px;
    top: 2839px;
    .pcr-mod28 {
      width: 200px;
      height: 33px;
      margin: 76px 0 0 144px;
      .pcr-info41 {
        width: 200px;
        height: 33px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 24px;
        letter-spacing: 0.47999998927116394px;
        font-family: AlibabaPuHuiTiM;
        white-space: nowrap;
        line-height: 33px;
        text-align: left;
      }
    }
    .pcr-mod29 {
      width: 372px;
      height: 28px;
      margin: 28px 0 0 64px;
      .pcr-mod36 {
        width: 22px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;
        margin-top: 4px;
      }
      .pcr-word62 {
        width: 342px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: left;
      }
    }
    .pcr-mod30 {
      width: 372px;
      height: 28px;
      margin: 20px 0 0 64px;
      .pcr-group7 {
        width: 22px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;

        margin-top: 4px;
      }
      .pcr-word63 {
        width: 342px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: left;
      }
    }
    .pcr-mod31 {
      width: 338px;
      height: 28px;
      margin: 20px 0 0 64px;
      .pcr-mod37 {
        width: 22px;
        height: 22px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;

        margin-top: 4px;
      }
      .pcr-txt30 {
        width: 308px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: left;
      }
    }
    .pcr-mod33 {
      width: 338px;
      height: 28px;
      margin: 20px 0 87px 64px;
      .pcr-outer8 {
        width: 22px;
        height: 21px;
        background: url(../../assets/images/procedure/tick.png)
          0px 0px no-repeat;

        margin-top: 4px;
      }
      .pcr-txt31 {
        width: 308px;
        height: 28px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(40, 40, 40, 1);
        font-size: 16px;
        letter-spacing: 1.3333333730697632px;
        font-family: AlibabaPuHuiTiR;
        white-space: nowrap;
        line-height: 28px;
        text-align: left;
      }
    }
  }
  .pcr-wrap6:hover {
    background-color: rgba(37, 184, 47, 1);
    box-shadow: 0px 20px 40px 0px rgba(37, 184, 47, 0.24);
    .pcr-info41,
    .pcr-word62,
    .pcr-word63,
    .pcr-txt30,
    .pcr-txt31 {
      color: rgba(255, 255, 255, 1);
    }
    .pcr-mod36,
    .pcr-group7,
    .pcr-mod37,
    .pcr-outer8 {
      background: url(../../assets/images/procedure/whitetick.png)
        0px 0px no-repeat;
    }
    .pcr-outer2 {
      width: 259px;
      height: 165px;
      background: url(../../assets/images/back/greencloth.png) 100%
        no-repeat;
      margin: -83px 0 0 114px;
    }
  }
}
</style>
<style src="../../assets/css/common.css" />